import React from 'react';
import { Link } from 'gatsby';
import { Row, Col } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import Helmet from 'react-helmet';

const SearchResultContainer = styled.div`
  & a {
    width: 100%;
    height: 100%;

    padding: 20px;
    margin-bottom: 40px;

    float: left;

    border: 2px solid #ff0000;

    text-decoration: none;
    color: #ff0000;

    &:hover {
      border: 2px solid #0000ff;
      color: #0000ff;
      & * {
        color: #0000ff;
      }
    }
  }
`;

const SearchQueryTitle = styled.h1`
  margin-bottom: 30px;
  text-align: center;
`;

const SearchResults = ({ location }) => {
  React.useEffect(() => {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      const Plyr = require('plyr');
      Array.from(document.querySelectorAll('.js-player')).map(p => new Plyr(p));
    }
  });
  const ifLocation = location !== null && location.state !== undefined;
  const searchResults = ifLocation && location.state.searchResults;
  const mapRes =
    ifLocation &&
    searchResults.map(obj => {
      const combo = {
        participants: obj.participants ? obj.participants : '',
        author: obj.author ? obj.author : '',
      };
      return Object.assign(obj, combo);
    });
  const results =
    ifLocation &&
    mapRes.filter(
      item =>
        item.title.includes(location.state.searchQuery) ||
        item.text.includes(location.state.searchQuery) ||
        item?.participants.includes(location.state.searchQuery) ||
        item?.author.includes(location.state.searchQuery)
    );
  // console.log(results, 'new');
  return (
    <>
      <Helmet title={`Search Results - Askeaton Contemporary Arts`} />
      <Col col={12} md={10} lg={8}>
        <Row justifyContent="center">
          <Col col={12} md={8}>
            <SearchQueryTitle>
              {location.state !== undefined &&
              location.state.searchQuery !== null
                ? `${results.length} results for ${location.state.searchQuery}`
                : `Search returned no results`}
            </SearchQueryTitle>
          </Col>

          {location !== null &&
          location.state !== null &&
          location.state !== undefined ? (
            <>
              {results.map((result, index) => {
                let createURL;

                if (result.type === 'event') {
                  createURL =
                    result.isCurrent === 'False' ||
                    result.isCurrent === undefined
                      ? `/archive/` + result.uid
                      : `/current/` + result.uid;
                }
                if (result.type === 'publication') {
                  createURL = `/publications/` + result.uid;
                }
                if (result.type === 'media') {
                  createURL = `/media/` + result.uid;
                }

                return (
                  <Col col={12} md={8} key={index}>
                    <SearchResultContainer>
                      <Link to={createURL}>
                        {result.title && <h2>{result.title}</h2>}
                        {result.author && <h2>{result.author}</h2>}
                      </Link>
                    </SearchResultContainer>
                  </Col>
                );
              })}
            </>
          ) : (
            <h1>Search Results</h1>
          )}

          {/* {location.state !== undefined && location.state.searchValues !== null
          ? location.state.searchValues.map((result, index) => {
              let createURL;

              if (result.type === 'event') {
                createURL =
                  result.isCurrent === 'False' || result.isCurrent === undefined
                    ? `/archive/` + result.uid
                    : `/current/` + result.uid;
              }
              if (result.type === 'publication') {
                createURL = `/publications/` + result.uid;
              }

              return (
                <Col col={12} md={8} key={index}>
                  <SearchResultContainer>
                    <Link to={createURL}>
                      {result.title && <h2>{result.title}</h2>}
                      {result.author && <h2>{result.author}</h2>}
                    </Link>
                  </SearchResultContainer>
                </Col>
              );
            })
          : null} */}
        </Row>
      </Col>
    </>
  );
};

export default SearchResults;
